var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"5","lg":"4"}},[_c('b-card',{attrs:{"bg-variant":"Info"}},[_c('h4',[_vm._v(_vm._s(_vm.infomodal.rag_soc))]),_c('p',[_vm._v(_vm._s(_vm.infomodal.indirizzo)),_c('br'),_vm._v(_vm._s(_vm.infomodal.cap)+" "+_vm._s(_vm.infomodal.localita)+" ("+_vm._s(_vm.infomodal.provincia)+")"),_c('br'),_vm._v(_vm._s(_vm.infomodal.paese))])]),_c('b-card',{attrs:{"bg-variant":"Info","title":"Info Attivazione Nuovo Utente"}},[_c('p',[_vm._v("Il CRM "),_c('u',[_vm._v("non")]),_vm._v(" invia la notifica di attivazione dell'utente pertanto è necessario comunicare le credenziali al cliente, nello specifico:")]),_c('ul',[_c('li',[_vm._v("Username")]),_c('li',[_vm._v("Password")]),_c('li',[_vm._v("Link per collegarsi al CRM")])])])],1),_c('b-col',{attrs:{"md":"7","lg":"8"}},[(_vm.submitted)?_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":"Success"}},[_c('b-button',{attrs:{"variant":"outline-success","to":{ name: 'crm-aziende' }}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CornerUpLeftIcon","size":"16"}}),_vm._v("Lista Aziende ")],1)],1):_vm._e(),(_vm.Caricato)?_c('b-card',{attrs:{"bg-variant":"Default","title":"Attivazione Nuovo Utente"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-form-checkbox',{staticClass:"mb-2",attrs:{"checked":"true","name":"attivo","switch":""},model:{value:(_vm.campiform.attivo),callback:function ($$v) {_vm.$set(_vm.campiform, "attivo", $$v)},expression:"campiform.attivo"}},[_vm._v(" Attiva Utente ")]),_c('b-form-group',{attrs:{"label":"Tipo di Utente","label-for":"id_tipo"}},[_c('validation-provider',{attrs:{"name":"Tipo di Utente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options,"state":errors.length > 0 ? false:null},on:{"change":function($event){return _vm.onChange($event)}},model:{value:(_vm.campiform.selected),callback:function ($$v) {_vm.$set(_vm.campiform, "selected", $$v)},expression:"campiform.selected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3480369133)})],1),(_vm.area_select_notifica)?_c('div',{staticClass:"mb-2"},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ArrowUpIcon","size":"21"}}),_vm._v(" Attenzione !!!")],1),_c('div',{staticClass:"alert-body"},[_vm._v("Attivando un Utente di tipo ADMIN, questo utente potrà vedere tutte le infomazioni di tutte le aziende")])])],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Nome","label-for":"nome"}},[_c('validation-provider',{attrs:{"name":"Nome o Nome e Cognome dell'Utente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.nome),callback:function ($$v) {_vm.$set(_vm.campiform, "nome", $$v)},expression:"campiform.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2115128756)})],1),_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"Username dell'utente per effettuare login","rules":"required|alpha-dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.username),callback:function ($$v) {_vm.$set(_vm.campiform, "username", $$v)},expression:"campiform.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('div',{staticClass:"text-info"},[_vm._v("Consiglio: combina parte del nome personale e della ragione sociale dell'azienda, es. leo_biomed")])]}}],null,false,2079895559)})],1),_c('b-form-group',{attrs:{"label":"password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password dell'utente per effettuare login","rules":"required|min:8|alpha-dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.password),callback:function ($$v) {_vm.$set(_vm.campiform, "password", $$v)},expression:"campiform.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('div',{staticClass:"text-info"},[_vm._v("Lunghezza minima 8 caratteri, caratteri alpha-numerici senza spazi")])]}}],null,false,3672782332)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email dell'utente","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.email),callback:function ($$v) {_vm.$set(_vm.campiform, "email", $$v)},expression:"campiform.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1272331633)})],1),_c('b-form-group',{attrs:{"label":"Recapito telefonico principale","label-for":"telefono"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.telefono),callback:function ($$v) {_vm.$set(_vm.campiform, "telefono", $$v)},expression:"campiform.telefono"}})],1),_c('b-form-group',{attrs:{"label":"Recapito telefonico secondario","label-for":"telefono2"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.telefono2),callback:function ($$v) {_vm.$set(_vm.campiform, "telefono2", $$v)},expression:"campiform.telefono2"}})],1),_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm(_vm.userData.id,_vm.infomodal.id)}}},[_vm._v(" Crea Nuovo utente ")])],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }